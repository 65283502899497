<template>
  <div class="login_mask" v-show="showLogin" @click.stop="closeLoginDialog">
    <section class="login" @click.stop="">
      <h6 class="login_tit">登录医学界，医学知识干货一键掌握</h6>
      <div @click="loginCheck" class="hand">
        <img
          v-show="loginShow == true"
          class="login_img"
          src="@/assets/images/login/login_ewm.png"
          alt=""
        />
        <img
          v-show="loginShow == false"
          class="login_img"
          src="@/assets/images/login/login_shouji.png"
          alt=""
        />
      </div>
      <div v-show="loginShow == false" class="login_ewm">
        <div class="ewm_title">扫码登录</div>
        <div v-show="ewmshow == false">
          <img v-show="ewmurl" class="ewm_img" :src="ewmurl" alt="" />
          <div class="ewm_text">打开 <span>医学界APP</span></div>
          <div class="ewm_content">
            <p v-if="obj" style="margin-bottom: 5px">{{ obj.one }}</p>
            <p v-else style="margin-bottom: 5px">
              在【我的】页面顶部打开扫一扫
            </p>
            <p v-if="obj" style="margin-bottom: 20px">{{ obj.two }}</p>
            <p v-else>如扫码异常请下载最新版客户端</p>
            <p
              v-if="obj"
              class="hand"
              style="margin: 0 auto; width: 45px"
              @click.self="moreRead"
            >
              {{ obj.text }}
            </p>
          </div>
        </div>
        <div v-show="ewmshow == true">
          <img
            class="ewm_cg"
            src="../../assets/images/login/chenggong.png"
            alt=""
          />
          <div class="cg_title">扫描成功</div>
          <div class="cg_test">请在手机上确认登录</div>
          <div class="cg_back" @click="backewm">返回二维码</div>
        </div>
      </div>
      <div v-show="loginShow == true" class="login_info">
        <div class="login_tabs">
          <button
            class="login_tabItem"
            @click="tabIndex = 0"
            :class="{ active: tabIndex === 0 }"
          >
            免密码登录
          </button>
          <button
            class="login_tabItem"
            @click="tabIndex = 1"
            :class="{ active: tabIndex === 1 }"
          >
            密码登录
          </button>
        </div>
        <!-- 验证码登录 -->
        <div class="login_item">
          <div class="login_input">
            <input
              v-model="mobile"
              type="text"
              maxlength="11"
              :placeholder="tabIndex === 0 ? '手机号' : '手机号或用户名'"
              @input="initTip"
            />
            <span class="login_tip" v-if="showTip">{{ tip }}</span>
          </div>
          <div class="login_input">
            <input
              v-model="pwd"
              minlength="6"
              maxlength="20"
              :type="tabIndex === 0 || !showPwd ? 'text' : 'password'"
              :placeholder="tabIndex === 0 ? '输入4位短信验证码' : '请输入密码'"
            />
            <button
              class="login_getCode"
              :class="{ gray: grayCode }"
              @click="getCode"
              v-if="tabIndex === 0"
              href="javascript:;"
            >
              {{ grayCode ? time + "s后重发" : "获取短信验证码" }}
            </button>
            <button
              class="icon"
              v-if="tabIndex === 1"
              @click="showPwd = !showPwd"
            >
              <span v-show="showPwd" class="iconfont icon-kejian"></span
              ><span v-show="!showPwd" class="iconfont icon-bukejian"></span>
            </button>
          </div>
          <button class="login_submit" @click="submit">
            {{ tabIndex === 0 ? "登录/注册" : "登录" }}
            <span
              v-if="readMore&&!cantClose"
              class="readMore hand"
              @click="closeLoginDialog"
              >再看看</span
            >
          </button>
        </div>
        <div class="login_statement" style="text-align: center">
          <p
            v-if="obj"
            style="margin: -25px auto 10px; color: #333333; width: 40px"
            @click.self="moreRead"
            class="hand"
          >
            {{ obj.text }}
          </p>
          <p>
            未注册手机验证后自动登录，注册即代表同意<a
              href="javascript:;"
              @click="privacy"
              >《隐私协议》</a
            >
          </p>
          <p v-if="obj" style="margin-bottom: 10px"></p>
          <p v-if="obj" style="margin-bottom: 5px; color: #333333">
            {{ obj.one }}
          </p>
          <p v-if="obj" style="margin-bottom: 20px; color: #333333">
            {{ obj.two }}
          </p>
        </div>
      </div>
      <div class="login_bottom">
        <p>APP注册认证领取<span>300</span>医米</p>
        <a
          href="https://a.app.qq.com/o/simple.jsp?pkgname=cn.org.yxj.doctorstation"
          target="_brack"
          >下载医学界医生站APP</a
        >
      </div>
    </section>
  </div>
</template>

<script>
import { Message } from "element-ui";
import cookies from "js-cookie";
export default {
  watch: {
    tabIndex() {
      this.showTip = false;
      this.pwd = "";
    },
    showLogin() {
      if (this.showLogin == true) {
        this.emwshow();
      }
    },
  },
  props: {
    prventClose: {
      type: Boolean,
      default: false
    },
    readMore: {
      type: Boolean,
      default: false
    },
    cantClose: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      obj: {},
      ewmshow: false,
      ewmurl: "",
      id: "",
      loginShow: false,
      showLogin: false,
      showIcon: false,
      showPwd: true,
      time: 10,
      grayCode: false,
      pwd: "",
      mobile: "",
      showTip: false,
      tabIndex: 0,
      tip: "请输入正确的手机号",
      time: null,
      status: "",
      stampTimer: null,
      stampObj: null,
    };
  },
  mounted() {
    if (this.$store.getters.stompClient) {
      // console.log(this.$store.getters.stompClient)
    }
  },
  methods: {
    initQRcodeWS() {
      if (this.stampObj) {
        //如果之前生成了接收对象，停止接收
        this.stampObj.unsubscribe();
      }
      this.stampObj = this.$store.getters.stompClient && this.$store.getters.stompClient.subscribe(
        "/qrcode/" + this.id,
        (msg) => {
          console.log("收到的信息", msg);
          // if (msg === 'CONFIRMED') {
          this.getid();
          // }
        }
      );
      console.log(1111111111111111, this.stampObj);
    },
    async emwshow() {
      try {
        var res = await this.$axios.$post(
          "/ysz-auth/oauth/createQrCodeAuthImage"
        );
        this.ewmurl = res.body.img;
        this.id = res.body.qrCodeId;
        // this.getid()
        if (this.stampTimer) {
          clearInterval(this.stampTimer);
        }
        // console.log("imgimgimg------", res);
        console.log('陈磊2');

        this.initQRcodeWS();
        this.stampTimer = setInterval(() => {
          console.log("判断ws是否链接成功并且生成client");
          if (this.$store.getters.stompClient) {
            // ws链接成功，接受对应端口信息，获取状态
            clearInterval(this.stampTimer);
            this.initQRcodeWS();
            // console.log(this.$store.getters.stompClient)
          } else {
            // ws链接不成功，直接获取扫码状态更改
            this.getid();
          }
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    async getid() {
      try {
        var res = await this.$axios.$post(
          "/ysz-auth/oauth/queryQrCodeAuthById",
          {
            id: this.id,
          }
        );
        this.status = res.body.status;
        if (this.status == "SCANNED") {
          this.ewmshow = true;
        } else if (this.status == "CONFIRMED") {
          clearInterval(this.stampTimer);
          this.stampObj && this.stampObj.unsubscribe();

          this.stampObj = null;
          var data = {
            client_id: "ysz",
            client_secret: "ysz",
            grant_type: "qrcode",
            scope: "all",
            qr_code_id: this.id,
          };
          var res = await this.$axios({
            method: "post",
            url: process.env.baseUrl + "ysz-auth/oauth/token",
            data: this.objectToFormData(data),
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });

          if (res.status === 200 && res.data.code === 200) {
            localStorage.setItem("loginInfo", JSON.stringify(res.data.body));
            cookies.set("token", res.data.body.access_token);
            this.$emit("loginSuccess");
            sensors.login(res.data.body.uid);
            this.showLogin = false;
            this.ewmshow = false;
          } else {
            this.tip = res.data.msg;
            this.showTip = true;
            console.log("error______", this.tip);
          }
        }
      } catch (error) {
        console.log(error);
        clearInterval(this.stampTimer);
      }
    },
    backewm() {
      this.ewmshow = false;
      this.emwshow();
    },
    moreRead() {
      this.showLogin = false
      this.$emit('loginLater')
    },
    loginCheck() {
      this.loginShow = !this.loginShow;
    },
    phonePasswordLogin() {
      this.loginShow = false
    },
    closeLoginDialog() {
      if (this.cantClose) {
        return
      }
      this.$emit('closeLogin')
      this.showLogin = false
    },
    privacy() {
      this.showLogin = false;
      this.$router.push("/about/privacy");
    },
    show(obj) {
      this.obj = obj;
      this.mobile = "";
      this.pwd = "";
      this.showTip = false;
      this.showLogin = true;
    },
    changeToLogin() {
      this.tabIndex = 1;
    },
    initTip() {
      console.log("initTip");
      this.showTip = false;
    },
    async getCode() {
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
        this.tip = "请输入正确的手机号";
        this.showTip = true;
        return false;
      }
      console.log("获取验证码");
      console.log(process.env.environment);
      try {
        this.showTip = false;
        var that = this;
        var res = await this.$axios.$post(
          process.env.baseUrl + "ysz-auth/auth/sms-code",
          { mobile: this.mobile }
        );
        console.log(res);
        Message({
          message: "发送成功",
          type: "success",
        });
        this.grayCode = true;
        this.time = 59;
        this.timer = setInterval(() => {
          this.time--;
          if (this.time <= 0) {
            clearInterval(this.timer);
            this.grayCode = false;
          }
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    objectToFormData(obj, form, namespace) {
      const fd = form || new FormData();
      let formKey;

      for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
          let key = Array.isArray(obj) ? "[]" : `[${property}]`;
          if (namespace) {
            formKey = namespace + key;
          } else {
            formKey = property;
          }

          // if the property is an object, but not a File, use recursivity.
          if (
            typeof obj[property] === "object" &&
            !(obj[property] instanceof File)
          ) {
            objectToFormData(obj[property], fd, formKey);
          } else {
            // console.log(123456,obj[property])
            // if it's a string or a File object
            fd.append(formKey, obj[property]);

          }
        }
      }
      // fd.forEach(ele => {
      //   console.log(111111,ele)
      // });
      return fd;
    },
    async submit() {
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
        this.tip = "请输入正确的手机号";
        this.showTip = true;
        return false;
      }
      if (!this.pwd) {
        if (this.tabIndex === 0) {
          this.tip = "验证码不能为空";
        } else {
          this.tip = "密码不能为空";
        }
        this.showTip = true;
        return;
      }
      var data = {
        client_id: "ysz",
        client_secret: "ysz",
        grant_type: this.tabIndex === 0 ? "sms" : "password",
        scope: "all",
        username: this.mobile,
        password: this.pwd,
        mobile: this.mobile,
        code: this.pwd,
      };
      this.showTip = false;
      let sendForm = this.objectToFormData(data)
      sendForm.forEach(ele => {
        console.log(22222222, ele)
      })
      console.log()

      try {
        // var res  =await
        // console.log(11111, this.$axios)

        var res = await this.$axios(
          {
            method: 'post',
            url: process.env.baseUrl + 'ysz-auth/oauth/token',
            data: sendForm,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })

        if (res.data.code === 200) {
          localStorage.setItem("loginInfo", JSON.stringify(res.data.body));
          localStorage.setItem("userInfo", JSON.stringify(res.data.body.user_info));
          cookies.set("token", res.data.body.access_token);
          this.$emit("loginSuccess");
          sensors.login(res.data.body.user_info.uid);
          this.showLogin = false;
          window.location.reload()
        } else {
          this.tip = res.data.msg;
          this.showTip = true;
        }
      } catch (error) {
        console.log(22222, error);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
input[type="password"]::-ms-reveal {
  display: none;
}
.login_mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000000;
}
.icon {
  color: #424242;
  span {
    font-size: 26px;
  }
}
.login {
  background: #ffffff;
  box-shadow: 1px 0px 5px 0px #9a9a9a;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  min-height: 500px;
  padding: 50px 0;
  .login_tit {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    background: #ffeded;
    border-radius: 2px 2px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    line-height: 50px;
  }
  .login_ewm {
    padding-top: 25px;
    .ewm_title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      text-align: center;
      margin-bottom: 25px;
    }
    .ewm_img {
      width: 141px;
      height: 141px;
      margin: 0 auto 26px;
    }
    .ewm_text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      text-align: center;
      margin-bottom: 14px;
      span {
        color: #d70c19;
      }
    }
    .ewm_content {
      p {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        text-align: center;
      }
    }
    .ewm_cg {
      width: 65px;
      height: 65px;
      margin: 54px auto 0;
    }
    .cg_title {
      font-size: 16px;
      font-weight: 500;
      color: #279828;
      line-height: 22px;
      text-align: center;
      margin-top: 14px;
    }
    .cg_test {
      font-size: 14px;
      font-weight: 400;
      color: #262626;
      line-height: 20px;
      text-align: center;
      margin-top: 49px;
    }
    .cg_back {
      font-size: 14px;
      font-weight: 400;
      color: #d70c19;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
    }
  }
  .login_img {
    width: 88px;
    height: 85px;
    position: absolute;
    right: 0;
  }
  .login_bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #f6f6f6;
    border-radius: 0px 0px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      span {
        color: #d70c19;
      }
    }
    a {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }
  }
  .login_info {
    padding: 10px 28px;
  }
  .login_tabs {
    display: flex;
    .login_tabItem {
      height: auto;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
      margin-right: 30px;
      position: relative;
      &.active {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 100%;
          width: 100%;
          height: 3px;
          background: #d70c19;
        }
      }
    }
  }
  .login_item {
    padding-top: 27px;
    white-space: nowrap;
    .login_input {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      .login_tip {
        font-size: 14px;
        font-weight: 400;
        color: #d22230;
        line-height: 38px;
      }
      .login_getCode {
        font-size: 14px;
        font-weight: 400;
        color: #0f66a8;
        line-height: 38px;
        &.gray {
          color: #999999;
        }
      }
      input {
        width: 120px;
        height: 38px;
        border: 0;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        &::placeholder {
          font-size: 14px;
          color: #999999;
        }
      }
    }
    .login_submit {
      position: relative;
      display: block;
      height: 40px;
      background: #d70c19;
      border-radius: 4px;
      width: 100%;
      margin: 42px 0 39px;
      .readMore {
        display: block;
        position: absolute;
        bottom: -32px;
        height: 22px;
        line-height: 22px;
        color: #333333;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .login_statement {
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    a {
      color: #0f66a8;
    }
  }
}
</style>